<template>
    <div @click="$emit('handleClick')" class="profile-toggle">
        <ProfileIconActive v-if="active"  />
        <ProfileIcon v-else />
    </div>
</template>

<script>
import ProfileIcon from "@/components/Nav/PwaNav/ProfileIcon/ProfileIcon";
import ProfileIconActive from "@/components/Nav/PwaNav/ProfileIcon/ProfileIconActive";
export default {
    name: "ProfileToggle",
    props: {
        active:{type: Boolean, default:false}
    },
    components: {
        ProfileIconActive,
        ProfileIcon
    }
}
</script>


<style lang="scss">

.profile-toggle{
    cursor: pointer;
    display: flex;
    width: 45px;
    justify-content: space-around;
    align-items: center;

    i.si-chevron-down {
        font-size: 8px;
        &:before {
            font-size: 8px;
            color: #FFFFFF;
        }
    }

}

</style>