<template>
    <div>
        <div class="search-results-summary desktop-only">
            <ResultsSummary/>
        </div>
        <div class="desktop-only">
            <FilterTags />
            <ClearFilters />
        </div>
        <SearchResultsHeaderPWA/>
        <SearchResultsBodyPWA/>
    </div>
</template>

<script>
import ClearFilters from "@/components/SearchResultFacetsPWA/Standards/ClearFilters";
import FilterTags from './FilterTags'
import SearchResultsHeaderPWA from "./SearchResultsHeaderPWA";
import SearchResultsBodyPWA from "./SearchResultsBodyPWA";
import ResultsSummary from "./ResultsSummary";

export default {
    name: 'SearchResultsContainerPWA',
    components: {
        SearchResultsHeaderPWA,
        SearchResultsBodyPWA,
        ResultsSummary,
        FilterTags,
        ClearFilters
    },

}
</script>
