<template>
    <div class="search-results-res">
        {{ getSummary }}
    </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex';
const {mapGetters} = createNamespacedHelpers('searchresults');
export default {
    name: "ResultsSummary",
    computed: {
        ...mapGetters([
            'getSearchResultsField'
        ]),
        getSummary() {
            let summary =  this.getSearchResultsField('total').toLocaleString() + ' results found ';
            if(this.getSearchResultsField('summary')) {
                summary += 'for "' + this.getSearchResultsField('summary') + '"';
            }
            return summary;
        }
    }
}
</script>
