<template>
    <div class="filter-tags-wrapper">
        <Tags field="colls"/>
        <Tags field="publishers"/>
        <Tags field="dcolls"/>
        <Tags field="include"/>
        <Tags field="types"/>
        <Tags field="tags"/>
    </div>
</template>

<script>
import Tags from '../Tags';
export default {
    name: "FilterTags.vue",
    components: {
        Tags
    }
}
</script>
<style lang="scss" scoped>
.filter-tags-wrapper {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    margin-bottom: 1em;
}
</style>
