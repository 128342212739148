<template>
    <div class="facet-filter-pwa">
        <SaiMultiSelect
            :class="['purple-theme' ,selectedOptions.length ? 'optionsSelected' : '' ]"
            :placeholder="placeholder"
            :value ="selectedOptions"
            :options="getSearchResultsFilterOptions(field)"
            @change="handleChange"
            :searchable="searchable"
            :hasClear="hasClear"
            labelBy ="value"
            trackBy="id"
            :name="name"/>
    </div>
</template>

<script>
import SaiMultiSelect from '@/components/common/SaiMultiSelect';
import {createNamespacedHelpers} from 'vuex';
const {mapGetters, mapActions} = createNamespacedHelpers('searchresults');
export default {
    name: "FacetSingleSelect.vue",
    model: {
        prop: 'value',
        event: 'change'
    },
    props: {
        name: { type: String,  default: '', required: true },
        placeholder: { type: String,  default: '', required: true },
        field: { type: String, default: '', required: true },
        searchable: {type: Boolean, default: false},
        hasClear: {type: Boolean, default: false},
    },
    components: {
        SaiMultiSelect
    },
    computed: {
        selectedOptions() {
            const selectedValues = [];
            this.getSearchResultsFilterOptions(this.field).forEach(option=> {
                if (this.getSearchResultsFilterSelectedValues(this.field).indexOf(String(option.id)) >=0) {
                    selectedValues.push(option)
                }
            })
            return selectedValues;
        },
        ...mapGetters([
            'getSearchResultsFilterOptions',
            'getSearchResultsFilterSelectedValues'
        ]),
    },
    methods: {
        ...mapActions([
            'setSearchResultsFilterSelectedValues',
            'setCanReset'
        ]),
        handleChange: function($value) {
            if ($value) {
                this.setCanReset(true);
                this.setSearchResultsFilterSelectedValues({ field:this.field ,fieldType:'radio', payload:$value.id});
            } else {
                this.setSearchResultsFilterSelectedValues({ field:this.field ,fieldType:'radio', payload:''});
            }

        },
    }

}

</script>
