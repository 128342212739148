<template>
    <form :class="[hasActions ? 'hasActions' : '','search_resutls_row_actions']"
          @click="toggleShowActions" v-click-outside="clickOutsideConfig" >
        <Loading
            :active="getDocRowActionStatus('addItemProcessing')(rowIndex)
                || getDocRowActionStatus('remItemProcessing')(rowIndex)
                || getDocRowActionStatus('watchItemProcessing')(rowIndex)
                || getDocRowActionStatus('unWatchItemGlobalProcessing')(rowIndex)
                || getDocRowActionStatus('unWatchItemProcessing')(rowIndex)"
            :is-full-page="false" loader="dots" color='#202020' :width="20" :height="20"
            :can-cancel="false"
            backgroundColor="#ffffff"
            :opacity="0.8"
            :z-index="999"
        />

        <div :class="[showActions ? 'showActionsList' : '', 'record-actions-container']">
            <div type="submit" v-if="isActionAllowed('addItem')"
                 :title="$t('frm_additem', [activeCollectionName])"
                 :value="'add:' + record.doc_id"
                 name="wxsubmit" @click.prevent="$event => rowAction($event, 'addItem')">
                <i class="si si-folder-clock"></i>
                <span>Add to collection</span>
            </div>


            <div type="submit" v-if="isActionAllowed('remItem')"
                 :title="$t('frm_remitem', [activeCollectionName])"
                 :value="'rem:' + record.doc_id"
                 name="wxsubmit" @click.prevent="$event => rowAction($event, 'remItem')">
                <i class="si si-folder-clock"></i>
                <span>Remove from collection</span>

            </div>


            <!-- watching/unwatching -->
            <div type="submit" v-if="isActionAllowed('watchItem')"
                 :title="$t('frm_watchitem', [activeCollectionName])"
                 :value="'watch:' + record.doc_id"
                 name="wxsubmit" @click.prevent="$event => rowAction($event,'watchItem')">
                <i class="si si-unwatch"></i>
                <span>Add to  watchlist</span>

            </div>

            <div type="submit" v-if="isActionAllowed('unWatchItemGlobal')"
                 :title="$t('frm_unwatch_global_err_msg')"
                 :value="'unwatch:' + record.doc_id"
                 name="wxsubmit" @click.prevent="$event => rowAction($event, 'unWatchItemGlobal')">
                <i class="si si-unwatch"></i>
                <span>Remove from Watchlist</span>

            </div>


            <div type="submit" v-if="isActionAllowed('unWatchItem')"
                 :title="$t('frm_unwatchitem', [activeCollectionName])"
                 :value="'unwatch:' + record.doc_id"
                 name="wxsubmit" @click.prevent="$event => rowAction($event, 'unWatchItem')">
                <i class="si si-unwatch"></i>
                <span>Remove from Watchlist</span>

            </div>
        </div>
    </form>

</template>
<script>
import vClickOutside from 'v-click-outside';
import Loading from 'vue-loading-overlay';
import {createNamespacedHelpers} from 'vuex';

const {mapGetters, mapActions} = createNamespacedHelpers('searchresults');
export default {
    data() {
        return {
            clickOutsideConfig: {
                handler: this.clickOutSideHandler,
                middleware: this.clickOutSideMiddleware,
                events: ['click']
            },
            showActions: false
        }
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    props: {
        record: {
            type: Object, default: () => {
            }
        },
        rowIndex: {type: Number, required: true},
    },
    components: {
        Loading
    },
    computed: {
        ...mapGetters([
            'getSearchResultsField',
            'getDocRowActionStatus',
            'getDocRowActions'

        ]),
        hasActions() {
          if (this.isActionAllowed('addItem') || this.isActionAllowed('remItem') ||
          this.isActionAllowed('unWatchItemGlobal') || this.isActionAllowed('watchItem') ||
          this.isActionAllowed('unWatchItem')) {
              return true;
          }
          return false;
        },
        activeCollectionName() {
            return this.getSearchResultsField('activeCollectionName');
        }
    },
    methods: {
        ...mapActions([
            'performDocAction'
        ]),
        toggleShowActions() {
            this.showActions = !this.showActions;
        },
        isActionAllowed(action) {
            const rowActions = this.getDocRowActions(this.rowIndex);
            return rowActions &&
                Object.prototype.hasOwnProperty.call(rowActions, action) &&
                rowActions[action];
        },
        rowAction($event, action) {
            const collectionId = this.getSearchResultsField('activeCollectionId');
            this.performDocAction({action, collectionId, docIds: [this.record.doc_id], isBulkAction: 0});
        },
        clickOutSideHandler () {
            this.showActions = false;
        },
    }
}
</script>
