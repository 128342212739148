<template>
    <div class="pwa-nav-right">
        <SearchToggle />
        <MagnifyToggle @handleClick="toggleSearchBar"
                       :active="isSearchOpen"
                       :class="isSearchOpen ? 'active' : ''" />
        <ProfileToggle @handleClick="toggleProfileMenu"
                       :active="isPwaNavOverlayRightVisible"
                       :class="isPwaNavOverlayRightVisible ? 'active' : ''" />
        <PwaOverLayMenuRight />
    </div>
</template>

<script>

import {createNamespacedHelpers} from "vuex";
const { mapGetters, mapActions } = createNamespacedHelpers('searchbar');
import MagnifyToggle from "@/components/Nav/MagnifyToggle";
import ProfileToggle from "@/components/Nav/ProfileToggle";
import PwaOverLayMenuRight from "@/components/Nav/PwaNav/PwaOverLayMenuRight";
export default {
    name: "PwaNavRight",
    components: {
        ProfileToggle,
        MagnifyToggle,
        PwaOverLayMenuRight
    },
    computed: {
        ...mapGetters([
            'isPwaNavOverlayRightVisible',
            'isSearchOpen'
        ])
    },
    methods: {
        ...mapActions([
            'togglePwaNavOverlayRight',
            'togglePwaNavOverlayLeft',
            'toggleSearchBar'
        ]),
        toggleProfileMenu() {
            this.togglePwaNavOverlayRight(!this.isPwaNavOverlayRightVisible);
            this.togglePwaNavOverlayLeft(false);
        }
    }
}
</script>

<style lang="scss" >
    .pwa-nav-right {
        display: flex;
        .si-search {
            padding: 15px 0;
            margin-right: 20px;
            &:before {
                color: #FFFFFF;
                font-size: 22px;
            }
        }
        .profile-toggle, .magnify-toggle {
            border-bottom: 4px solid transparent;
            padding: 15px 0;
            &.active {
                border-bottom: 4px solid #FFBD35;
            }
        }
    }
</style>