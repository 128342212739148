<template>
    <div class="popup">
        <div class="popup-header">
            <div class="popup-header-title">
              <h3>Get started with i2i</h3>
                <span id="tour-guide-close">
                  <span @click="$emit('closePopup')">
                      <i class="si si-cross"></i>
                  </span>
                </span>
            </div>
            <div class="popup-header-progressBar">
                <h5 class="header-progress-title">Your progress</h5>
                <h4>{{ progress }} % completed</h4>
                <div class="progressBar-container">
                    <ProgressBar :progress="progress"/>
                    <span>&nbsp;{{ progress }}%</span>
                </div>
            </div>
        </div>
        <div class="popup-body" id="popup-body" v-if="getTourGuides.length">
            <SaiAccordionSecondary
                v-for="section in getTourGuides"
                :key="section.id"
                :id="'acc' + section.id"
                :headerTitle="section.title"
                @clickHeader="handleSectionVisit(section.id, section.completed)"
                :headerIcon="['tickIcon', section.completed ? 'visited' :'']">
                <div slot="accordion-content">
                    <iframe :id="section.id" width="100%" height="200" frameBorder="0" allow="fullscreen;"
                            :src="section.video">
                    </iframe>
                    <p v-html="section.text"></p>
                </div>
            </SaiAccordionSecondary>
        </div>

    </div>
</template>

<script>
import ProgressBar from "@/components/common/ProgressBar";
import SaiAccordionSecondary from "@/components/common/SaiAccordionSecondary";
import {createNamespacedHelpers} from "vuex";

const {mapGetters, mapActions} = createNamespacedHelpers('tourguide');

export default {
    name: "touGuidePopup",
    computed: {
        ...mapGetters([
                'getTourGuides',
            ]
        ),
        progress() {
            const tourGuideCount = this.getTourGuides.length;
            let completedSections = 0
            this.getTourGuides.forEach(tourGuide => {
                if (tourGuide.completed) {
                    completedSections++;
                }
            })
            return Math.round((completedSections / tourGuideCount) * 100);
        },
    },
    components: {
        ProgressBar,
        SaiAccordionSecondary
    },
    methods: {
        ...mapActions([
            'updateTourGuideSettings',
        ]),
        handleSectionVisit(id, completed) {
            // collapse all other accordions
            this.collapseAllAccordions(id)

            if (!completed) {
                this.updateTourGuideSettings(id)
            }
        },
        collapseAllAccordions(id) {
            const popup = document.getElementById('popup-body')
            const clickedAccordion = document.getElementById('acc' + id)

            if (!clickedAccordion.classList.contains('active')) {
                const accordions = popup.querySelectorAll('.sai-accordion-secondary')
                accordions.forEach(accordion => {
                    if (accordion.classList.contains('active') && accordion.id !== 'acc' + id) {
                        accordion.getElementsByClassName('accordion-header-title')[0].click()
                    }
                })
            }
            setTimeout(() => {
              const clickedAccordionOffset = clickedAccordion.offsetTop - popup.offsetTop
              popup.scrollTo(0, clickedAccordionOffset )
            }, 100)

        }
    }
}
</script>

<style lang="scss">
@import "../tourGuide";
</style>