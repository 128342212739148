<template>
    <span @click="$emit('handleClick')" class="nav-hamburger">
         <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.25 18.0029H21.75" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.25 12.0029H21.75" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M2.25 6.00293H21.75" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
         </svg>

    </span>
</template>

<script>
export default {
    name: "BurgerMenu",
}
</script>
