<template>
    <LeftNavItems />
</template>

<script>
const pwa_nav_overlay_vars = window.pwa_nav_overlay_vars || "";
import LeftNavItems from "./LeftNavItems";

import {createNamespacedHelpers} from "vuex";
const {mapActions} = createNamespacedHelpers('searchbar');
export default {
    name: "index",
    created() {
        if (typeof (pwa_nav_overlay_vars) != "undefined" && pwa_nav_overlay_vars !== '') {
            this.setPwaNavOverlayVars(JSON.parse(Buffer.from(pwa_nav_overlay_vars, "base64")));
        }
    },
    components: {
        LeftNavItems
    },
    methods: {
        ...mapActions([
            'setPwaNavOverlayVars'
        ]),

    }
}
</script>
