<template>
    <div class="filterInputFacet">
        <SaiInput class="sai-input"
                  :value="getSearchResultsFilterValue(field)"
                  @input="handleInputChange"
                  :placeholder="placeholder" type="text" :name="name"/>
    </div>
</template>

<script>
    import {createNamespacedHelpers} from 'vuex';
    const {mapGetters, mapActions} = createNamespacedHelpers('searchresults');
    import SaiInput from '@/components/common/SaiInput';
    export default {
        name: "AccordionFilterInput",
        components: {
            SaiInput
        },
        props: {
            placeholder:{type:String, default:''},
            name:{type:String, default:''},
            field: { type: String, default: '', required: true },
        },
        computed: {
            ...mapGetters([
                'getSearchResultsFilterValue',
            ]),
        }, 
        methods: {
            ...mapActions([
                'setSearchResultsFilterSelectedValues'
            ]),
            handleInputChange($event) {
                this.setSearchResultsFilterSelectedValues({ field: this.field, fieldType:'input', payload: $event});
            }
        }
    }
</script>
