<template>
    <SaiMobileModal :display="isPwaNavOverlayRightVisible"
                    :hasCloseIcon="false"
                    class="pwaOverlayModal pwaOverlayModalRight">
        <div slot="content" class="overlayModalContent">
            <div v-if="userInfo">
                <ul class="overlay-content-user">
                    <li>
                        <a>
                            <strong>{{userInfo['userName']}}</strong>
                        </a>
                    </li>
                    <li>
                        <a>
                            {{userInfo['userEmail']}}
                        </a>
                    </li>
                </ul>
            </div>

            <div v-if="profileMenuLinks">
                <ul v-for="menuLink in profileMenuLinks" :key="menuLink.linkName">
                    <li v-if="menuLink.linkHref.indexOf('/globalnotes/') >= 0">
                        <a :href="menuLink.linkHref" :target="menuLink.linkTarget"
                           @click="ActivateLoader">
                            {{menuLink.linkName}}
                            <i class="pwa-link-counter" v-if="globalNotesCounter > 0">
                                {{globalNotesCounter}}
                            </i>
                        </a>
                    </li>

                    <li v-else>
                        {{menuLink.linkTarget}}
                        <a v-if="menuLink.linkHref ==='_blank'" :href="menuLink.linkHref"
                           :target="menuLink.linkTarget">
                            {{menuLink.linkName}}
                        </a>
                        <a v-else :href="menuLink.linkHref"
                           :target="menuLink.linkTarget"
                           @click=" ActivateLoader">
                            {{menuLink.linkName}}
                        </a>
                    </li>
                </ul>
            </div>

            <div v-if="powerLink['powerHref'] && powerLink['powerName'] ">
                <ul>
                    <li>
                        <a  :href="powerLink['powerHref']"  @click="ActivateLoader">{{ powerLink['powerName'] }}</a>
                    </li>
                </ul>
            </div>

            <div v-if="logoutLink">
                <ul>
                    <li>
                        <a class="text-purple" :href="logoutLink.linkHref"  @click="ActivateLoader">
                            <strong>
                                {{logoutLink.linkName}}
                            </strong>
                        </a>
                    </li>
                </ul>
            </div>

        </div>
    </SaiMobileModal>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const {mapGetters} = createNamespacedHelpers('searchbar');
import SaiMobileModal from "@/components/common/SaiMobileModal";

export default {

    computed: {
        ...mapGetters([
            'getPwaNavProfileMenuLinks',
            'isPwaNavOverlayRightVisible'
        ]),
        userInfo() {
            return this.getPwaNavProfileMenuLinks['userDetails'];
        },
        profileMenuLinks() {
            return this.getPwaNavProfileMenuLinks['menuLinks'];
        },
        logoutLink() {
            return this.getPwaNavProfileMenuLinks['logout'];
        },
        globalNotesCounter() {
            return this.getPwaNavProfileMenuLinks['notesCounter'];
        },
        powerLink() {
            return this.getPwaNavProfileMenuLinks['powerToggle'] ?? '';
        }
    },
    components: {
        SaiMobileModal,
    },
    methods: {
        ActivateLoader() {
            this.$loading.show();
        }
    }
}
</script>

<style lang="scss">
    @import "PwaOverLay";
</style>