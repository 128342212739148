<template>
    <div @click="$emit('handleClick')" class="magnify-toggle">
        <MagnifyIconActive v-if="active"  />
        <MagnifyIcon v-else />
    </div>
</template>

<script>
import MagnifyIcon from "@/components/Nav/PwaNav/MagnifyIcon/MagnifyIcon";
import MagnifyIconActive from "@/components/Nav/PwaNav/MagnifyIcon/MagnifyIconActive";
export default {
    name: "MagnifyToggle",
    components: {
        MagnifyIconActive,
        MagnifyIcon
    },
    props: {
        active:{type: Boolean, default:false}
    },
}
</script>

<style lang="scss" >
    .magnify-toggle{
        cursor: pointer;
        display: flex;
        width: 45px;
        justify-content: space-around;
        align-items: center;
    }
</style>