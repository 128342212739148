<template>
    <div class="sai-bar">
        <span class="sai-bar-fill" :style="{width: progress + '%'}"></span>
    </div>
</template>

<script>
export default {
    name: "ProgressBar",
    props: {
        progress: {}
    }
}
</script>

<style scoped lang="scss">
@import "~@/css/base/base";

.sai-bar {
    background-color: #D0D5DD;
    border-radius: 10px;
    height: 10px;
    display: flex;
    width: 100%;

    .sai-bar-fill {
        height: 100%;
        border-radius: 10px;
        background-color: $c22;
    }
}
</style>