<template>
    <div class="advanced-filter-row">
        <div class="form-row advanced-standards-filter">
            <div class="search-results-summary mobile-only">
                <ResultsSummary/>
            </div>
            <div class="mobile-only">
                <span @click="toggleMobileFilters" class="advanced-filter-toggler">
                   {{ hiddenInMobile ? 'Show filters' : 'Hide filters' }}
                </span>
            </div>
            <div class="filterBy-header" :class="hiddenInMobile && 'hiddenInMobile'">
              {{$t('frm_filter_by')}} :
            </div>
            <div class="filters-tag-section mobile-only">
                <FilterTags/>
                <ClearFilters/>
            </div>
            <div class="facets-filters-container" :class="hiddenInMobile && 'hiddenInMobile'">
                <FacetMultiSelect :placeholder="$t('frm_my_collections')"
                                  :searchable="true"
                                  :hasClear="true"
                                  name="filter[colls][]"
                                  field="colls"/>

                <FacetMultiSelect v-if="getSearchResultsFilterOptions('publishers').length"
                                  :placeholder="$t('fld_publisher')"
                                  :searchable="true"
                                  :hasClear="true"
                                  name="filter[publisher][]"
                                  field="publishers" />

                <FacetMultiSelect :placeholder="$t('frm_company_collections')"
                                  :searchable="true"
                                  :hasClear="true"
                                  name="filter[dcoll][]"
                                  field="dcolls" />

                <FacetMultiSelect :placeholder="$t('frm_current_label')"
                                  :searchable="true"
                                  :hasClear="true"
                                  name="filter[include][]"
                                  field="include" />

                <FacetSingleSelect :placeholder="$t('frm_document_type')"
                                  :searchable="true"
                                  :hasClear="true"
                                  name="filter[type][]"
                                  field="types" />

                <FacetMultiSelect :placeholder="$t('frm_tags')"
                                  :searchable="true"
                                  :hasClear="true"
                                  name="filter[tags][]"
                                  field="tags" />


                <div class="filters-cba">
                    <div class="apply-filter">
                        <button class="sai-btn-purple" @click="applyFilter">{{ $t('frm_apply')}}</button>
                    </div>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
import FilterTags from "@/components/SearchResultsPWA/Collections/FilterTags";
import ResultsSummary from "../../SearchResultsPWA/Collections/ResultsSummary";
import FacetMultiSelect from '../FacetMultiSelect';
import FacetSingleSelect from '../FacetSingleSelect';
import {createNamespacedHelpers} from 'vuex';
import ClearFilters from './ClearFilters';

const {mapGetters, mapActions} = createNamespacedHelpers('searchresults');


export default {
    name: 'SearchResultFacetsCollections',
    data() {
        return {
            hiddenInMobile: true
        }
    },
    components: {
        FacetMultiSelect,
        ResultsSummary,
        FilterTags,
        ClearFilters,
        FacetSingleSelect
    },
    computed: {
        ...mapGetters([
            'getSearchResultsFilterOptions',
        ]),
    },
    methods: {
        ...mapActions([
            'setCurrentPage',
            'fetchItems',
            'setSearchResultsFilterSelectedValues',
            'setPWAStatus'
        ]),
        applyFilter() {
            this.setPWAStatus(false);
            window.scrollTo(0, 0);
            this.setCurrentPage(1);
            this.fetchItems('collections');
        },
        toggleMobileFilters() {
            this.hiddenInMobile = !this.hiddenInMobile;
        }
    }
}
</script>
