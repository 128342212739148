<template>
    <div class="clear-filters" v-if="enableByDefaultReset">
        <a href="#" v-on:click.prevent="reset()" class="clear-fields-filter">
            <i class="si si-cross"></i>
            Clear all filters
        </a>
    </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const { mapGetters, mapActions} = createNamespacedHelpers('searchresults');

const fields = ['colls','publishers', 'include', 'dcolls', 'tags', 'types'];
export default {
    name: "ClearFilters.vue",
    computed: {
        ...mapGetters([
            'getSearchResultsFilterSelectedValues',
            'getSearchResultsFilterValue'
        ]),
        enableByDefaultReset() {
            let canReset = false;
            fields.forEach(field => {
                if (this.getSearchResultsFilterSelectedValues(field).length > 0 ) {
                    canReset = true;
                }
            });
            return canReset;
        }
    },
    methods: {
        ...mapActions([
            'setSearchResultsFilterSelectedValues',
        ]),
        reset() {
            fields.forEach(field => {
                if (this.getSearchResultsFilterSelectedValues(field).length) {
                    if (field === 'types') {
                        this.setSearchResultsFilterSelectedValues({field: field, fieldType: 'radio', payload: ' '});
                    } else {
                        this.setSearchResultsFilterSelectedValues({field: field, fieldType: 'checkbox', payload: []})
                    }
                }
            });
            document.querySelector('.apply-filter .sai-btn-purple').click();
        }
    }
}
</script>
