<template>
    <fragment>
        <SearchResultFacetsPWA/>
        <SearchResultsContainerPWA class="results-container"/>
    </fragment>
</template>

<script>
import SearchResultFacetsPWA from '@/components/SearchResultFacetsPWA';
import SearchResultsContainerPWA from '@/components/SearchResultsPWA/Collections/SearchResultsContainerPWA'
export default {
    name: "index.vue",
    components: {
        SearchResultFacetsPWA,
        SearchResultsContainerPWA,
    },
}
</script>

<style scoped>

</style>