<template>
    <div class="filter-tags">
        <div class="tag-container" v-for="tag in selectedTags" :key="tag.id">
            <span class="tag-text">{{tag.value}}</span>
            <span class="tag-close" @click="removeTag(tag.id)">
                <i class="si si-cross"></i>
            </span>
        </div>
    </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex';
const {mapGetters, mapActions} = createNamespacedHelpers('searchresults');

export default {
    name: "Tags",
    props: {
        field: { type: String, default: '', required: true },
        singleSelect: {type: Boolean, default: false }
    },
    computed: {
        ...mapGetters([
            'getSearchResultsFilterSelectedValues',
            'getSearchResultsFilterOptions',
        ]),
        selectedTags() {
            const selectedValues = [];
            this.getSearchResultsFilterOptions(this.field).forEach(option=> {
                if (this.getSearchResultsFilterSelectedValues(this.field).indexOf(String(option.id)) >=0) {
                    selectedValues.push(option)
                }
            })
            return selectedValues;
        }
    },
    methods: {
        ...mapActions([
            'setSearchResultsFilterSelectedValues',
        ]),
        removeTag(id) {
            const UpdatedTags = this.getSearchResultsFilterSelectedValues(this.field).filter( selectedValues => {
               return selectedValues !== String(id)
            });
            this.setSearchResultsFilterSelectedValues({ field:this.field ,fieldType:'checkbox', payload:UpdatedTags});

        }
    }
}
</script>

<style lang="scss" scoped>
    .filter-tags {
        display: flex;
        flex-wrap: inherit;
        .tag-container {
            width: auto;
            display: flex;
            border-radius: 100px;
            background: #DFD9E0;
            padding: 12px;
            margin:10px 12px 0 0;
            .tag-text {
                font-size: 13px;
                font-weight: 500;
                max-width: 200px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                margin-right: 5px;
                color: #2B313A;
                @media (max-width:768px) {
                    font-size: 11px;
                }
            }
            .tag-close {
                cursor: pointer;
            }
        }
    }

</style>