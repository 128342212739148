<template>
    <div class="tour-guide-tag">
        <Loading :active="getLoadingStatus"
                 :is-full-page="true"
                 loader="dots"
                 color='#202020'/>
        <div id="tourGuide-btn" class="tourGuide-btn" @click="showGuide">
            <i class="si si-light"></i>
            <span>Tutorials</span>
        </div>
        <div class="tour-guide-wrapper">
            <TourGuidePopUp @closePopup="hideGuide" v-if="isVisibleTourGuide"/>
        </div>
    </div>
</template>

<script>
import TourGuidePopUp from "@/components/TourGuide/TourGuidePopUp";
import {createNamespacedHelpers} from "vuex";
import Loading from "vue-loading-overlay";

const {mapGetters, mapActions} = createNamespacedHelpers('tourguide');

export default {
    name: "index",

    data() {
        return {
            isVisibleTourGuide: false
        }
    },
    components: {
        TourGuidePopUp,
        Loading
    },
    computed: {
        ...mapGetters([
            'getTourGuides',
            'getLoadingStatus'
        ]),
    },
    methods: {
        ...mapActions([
            'fetchTourGuides'
        ]),
        async showGuide() {
            if (this.getTourGuides.length === 0) {
                await this.fetchTourGuides();
            }
            this.isVisibleTourGuide = true
        },
        hideGuide() {
            this.isVisibleTourGuide = false
        }
    },
}
</script>

<style lang="scss">
@import "tourGuide";
</style>