<template>
  <div>
    <a class="si si-search" :title="$t('tooltip_icon_search')" @click="toggleClick"></a>
  </div>
</template>
<script>
import {createNamespacedHelpers} from 'vuex';
const {mapActions} = createNamespacedHelpers('searchbar');
export default {
  name: "SearchToggle",
  methods: {
    ...mapActions([
      'toggleSearchBar'
    ]),
    toggleClick() {
        this.toggleSearchBar();
          window.scrollTo( {top: 0, behavior: 'smooth'} );
    }
  }
}
</script>