<template>
    <div class="facet-filter-pwa">
        <SaiMultiSelect
            :multiple="true"
            :placeholderIsTitle="true"
            :class="['hasCheckbox', 'purple-theme' ,selectedOptions.length ? 'optionsSelected' : '' ]"
            :placeholder="placeholder"
            :value ="selectedOptions"
            @change="handleCheckboxChange"
            :options="getSearchResultsFilterOptions(field)"
            :searchable="searchable"
            :hasClear="hasClear"
            labelBy ="value"
            trackBy="id"
            :name="name"/>
    </div>

</template>
<script>
    import SaiMultiSelect from '@/components/common/SaiMultiSelect';
    import {createNamespacedHelpers} from 'vuex';
    const {mapGetters, mapActions} = createNamespacedHelpers('searchresults');
    export default {
        props: {
            name: { type: String,  default: '', required: true },
            placeholder: { type: String,  default: '', required: true },
            field: { type: String, default: '', required: true },
            searchable: {type: Boolean, default: false},
            hasClear: {type: Boolean, default: false},
        },
        components: {
            SaiMultiSelect
        },

        computed: {
            ...mapGetters([
                'getSearchResultsFilterOptions',
                'getSearchResultsFilterSelectedValues'
            ]),
            selectedOptions() {
                const selectedValues = [];
                this.getSearchResultsFilterOptions(this.field).forEach(option=> {
                    if (this.getSearchResultsFilterSelectedValues(this.field).indexOf(String(option.id)) >=0) {
                        selectedValues.push(option)
                    }
                })
               return selectedValues;
            }
        },
        methods: {
            ...mapActions([
                'setSearchResultsFilterSelectedValues',
                'setCanReset'
            ]),
            handleCheckboxChange: function($arrValues) {
                const $values =  $arrValues.map(value=> {
                    return value.id
                });
                this.setCanReset(true);
                this.setSearchResultsFilterSelectedValues({ field:this.field ,fieldType:'checkbox', payload:$values});
            },
        }
    }
</script>