<template>
    <div :class="['sai-accordion-secondary', isExpanded ? 'active' : '']">
        <div class="accordion-header">
            <div class="accordion-header-title" @click="headerClickHandler">
                <span :class="['accordion-header-arrow', isExpanded ? 'active' : '']">
                </span>
                <span class="header-title">{{ headerTitle }}</span>
            </div>
            <div class="accordion-header-icon">
                <span class="header-icon">
                    <i :class="headerIcon"></i>
                </span>
            </div>
        </div>
        <div :class="['accordion-body' , !isExpanded ?  'collapsed' : '']">
            <slot name="accordion-content"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "SaiAccordionSecondary",
    props: {
        headerTitle: {type: String, default: '',},
        headerIcon: {type: String, default: ''},
    },
    data() {
        return {
            isExpanded: false
        }
    },
    methods: {
        headerClickHandler() {
            this.$emit('clickHeader');
            this.isExpanded = !this.isExpanded
        }
    }
}
</script>

<style scoped>

</style>