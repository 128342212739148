<template>
    <div id="resPane">
        <div class="search-results-topBar">
            <div class="searchDocument">
                <div class="sai-options-purple">
                    <div class="options">
                        <input type="checkbox" id="searchCheckbox" @change="selectAllRecords">
                        <label for="searchCheckbox">{{ $t('frm_document')}}</label>
                    </div>
                </div>
            </div>
            <div class="searchAction">{{ $t('frm_action')}}</div>
        </div>
        <SearchResultsRecordPWA v-for="(record, index) in getSearchResultsField('records')"
                                :key="record.doc_id"
                                :record="record"
                                :index="index"
        />

        <div v-if="getSearchResultsField('hits') > getSearchResultsField('recordsPerPage')"
             class="sai-data-list-pagination">
            <Loading :active="this.getLoadMoreLoadingStatus"
                     :is-full-page="false" loader="spinner" color='black' :width="40" :height="40"
                     :can-cancel="false"
                     :lock-scroll="true"
                     backgroundColor="white"
                     :opacity="0.9"
                     :z-index=999
            />
            <div class="scroll-top-box" v-if="showScrollToTop">
                <div @click="scrollTop">
                    <span class="scroll-top-text">
                        {{ $t('scroll_to_top')}}
                    </span>
                    <i class="top-arrow">&uarr;</i>
                </div>
            </div>

        </div>

        <div v-else-if="getSearchResultsField('nohits')">
            <p>{{ $t('no_results_reset_filters_search')}}</p>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import {debounce} from '@/plugins/axios';
import {createNamespacedHelpers} from 'vuex';
import SearchResultsRecordPWA from "@/components/SearchResultsPWA/Collections/SearchResultsRecordPWA";

const {mapGetters, mapActions} = createNamespacedHelpers('searchresults');

export default {
    name: 'SearchResultsBodyPWA',
    components: {
        SearchResultsRecordPWA,
        Loading
    },
    data() {
        return {
            showScrollToTop: false
        }
    },
    mounted() {
        this.handleDebouncedScroll = debounce(this.handleScroll, 100);
        window.addEventListener('scroll', this.handleDebouncedScroll);
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.handleDebouncedScroll);
    },
    computed: {
        ...mapGetters([
            'getSearchResultsField',
            'getLoadMoreLoadingStatus',
            'getPWAStatus',
            'getLoadingStatus'
        ]),
    },
    methods: {
        ...mapActions([
            'setCurrentPage',
            'fetchItems',
            'setBulkSelectedRecords',
            'setPWAStatus',
        ]),
        handleScroll(e) {
            //max number of records should be less than 200
            const maxHitsToShow = (this.getSearchResultsField('hits') > 200) ? 200 : this.getSearchResultsField('hits');
            const numberOfRecords = this.getSearchResultsField('records').length;
            const pageHasLoadMore = (this.getSearchResultsField('hits') > this.getSearchResultsField('recordsPerPage'))
                && (numberOfRecords < maxHitsToShow ) ;

            if (pageHasLoadMore && (!this.getLoadMoreLoadingStatus) && (!this.getLoadingStatus)) {
                const footerHeight = document.getElementById('footerPane').scrollHeight;

                if (document.documentElement.scrollHeight <= window.pageYOffset + window.innerHeight + footerHeight) {
                    this.loadMore(e, (this.getSearchResultsField('page') + 1));
                }
            }

            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                this.showScrollToTop = true;
            } else {
                this.showScrollToTop = false;
            }
        },
        loadMore(event, pageNo) {
            this.setPWAStatus(true);
            this.setCurrentPage(pageNo);
            this.fetchItems('collections');
        },
        scrollTop() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        },

        selectAllRecords($event) {
            let selectedRecords = [];
            if ($event.target.checked) {
                this.getSearchResultsField('records').forEach(records => {
                    selectedRecords.push(String(records.doc_id));
                })
            }
            this.setBulkSelectedRecords(selectedRecords);
        }
    },
}
</script>

<style lang="scss" scoped>
    .vld-overlay {
        top: unset;
        bottom: 10px;
    }
</style>