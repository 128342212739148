<template>
    <div :class="display ? 'sai-mobile-modal show' : 'sai-mobile-modal' ">
        <div v-if="hasCloseIcon" class="modal-close-container" @click="closeModal">
            <span class="close-icon">&#10006;</span>
        </div>
        <div class="mobile-modal-content">
            <slot name="content"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: "SaiMobileModal",
        props: {
            display: {type: Boolean, default: false},
            saveTitle: {type:String, default:'Done'},
            hasCloseIcon: {type: Boolean, default: true}
        },
        methods: {
            closeModal: function() {
                this.$emit('closeModal')
            }
        }
    }
</script>
