<template>

    <div v-if="getSearchResultsField('isSearchResultActive')" id="contentPane" :class="getLoadingStatus ? 'searchResultsPane skeleton-loader' : 'searchResultsPane '" style="visibility: visible;">
        <template v-if="getSearchResultsField('nohits')">
            <div class="no-results">
                <p>
                    <strong>No documents could be located for your entered search terms. Please check your spellings and/or broaden your search criteria.</strong>
                </p>
            </div>
        </template>
        <template v-else>
            <Standards v-if="isActiveSearchTab('search.standards')" />
            <Collections v-if="isActiveSearchTab('search.assets')"/>
        </template>
    </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex';
import Standards from './Standards';
import Collections from './Collections';
const {mapGetters, mapActions} = createNamespacedHelpers('searchresults');
const search_results_data = window.search_results_data || '';
const search_results_active_tab = window.search_results_active_tab || '';

export default {
    name: 'SearchResultsPWA',
    components: {
        Standards,
        Collections
    },
    computed: {
        ...mapGetters([
            'getLoadingStatus',
            'getSearchResultsField',
            'isActiveSearchTab',
            'isLiteSubscription'
        ])
    },
    mounted() {
        if (typeof (search_results_data) != "undefined" && search_results_data !== '') {
            //console.log('search_results_data', search_results_data);
            this.setSearchResults(JSON.parse(Buffer.from(search_results_data, "base64")));
        }

        if (typeof (search_results_active_tab) != "undefined" && search_results_active_tab !== '') {
            // console.log('search_results_active_tab', search_results_active_tab);
            this.setActiveSearchTab(JSON.parse(Buffer.from(search_results_active_tab, "base64")));
        }
    },
    methods: {
        ...mapActions([
            'setSearchResults',
            'setActiveSearchTab',
        ])
    }
}
</script>

<style lang="scss" >
    @import "SearchResultsPWA";
</style>