<template>
    <div class="pwa-nav-left">
        <BurgerToggle @handleClick="openBurgerMenu"/>
        <img class="sai-logo" src="/common/skin/i2i/img/logo.png"
             alt="Intertek Inform">
        <PwaOverLayMenuLeft />
    </div>
</template>

<script>

import {createNamespacedHelpers} from "vuex";
const { mapActions } = createNamespacedHelpers('searchbar');
import BurgerToggle from '../../BurgerToggle'
import PwaOverLayMenuLeft from "@/components/Nav/PwaNav/PwaOverLayMenuLeft";
export default {
    name: "PwaBurgerMenu",
    components: {
        BurgerToggle,
        PwaOverLayMenuLeft
    },

    methods: {
        ...mapActions([
            'togglePwaNavOverlayLeft',
            'togglePwaNavOverlayRight',
        ]),
        openBurgerMenu() {
            this.togglePwaNavOverlayLeft(true);
            this.togglePwaNavOverlayRight(false);
        }
    }
}
</script>
<style lang="scss">
    .pwa-nav-left {
        display: flex;
        padding: 15px 0;
        .nav-hamburger {
            cursor: pointer;
        }
    }
</style>