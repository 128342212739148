<template>
    <div class="filter-tags-wrapper">
        <Tags field="publisher"/>
        <Tags field="include"/>
        <Tags field="country"/>
        <Tags field="language"/>
        <Tags field="year"/>
    </div>
</template>

<script>
import Tags from '../Tags';
export default {
    name: "FilterTags.vue",
    components: {
        Tags
    }
}
</script>
<style lang="scss" scoped>
.filter-tags-wrapper {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    margin-bottom: 1em;
}
</style>
