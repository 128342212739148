<template>
    <SaiMobileModal :display="isPwaNavOverlayLeftVisible" @closeModal="hideModal" class="pwaOverlayModal pwaOverlayModalLeft">

        <div slot="content" class="overlayModalContent" v-if="systemMessage">
            {{ systemMessage }}
        </div>

        <div slot="content" class="overlayModalContent" v-else>
            <div v-if="secondaryLinks.length">
                <ul v-for="secondaryLink in secondaryLinks" :key="secondaryLink.linkName">
                    <li v-if="secondaryLink.linkName.indexOf('/unlock/') >= 0">
                        <a :href="secondaryLink.linkHref" :target="secondaryLink.target" @click="ActivateLoader">
                            {{ secondaryLink.linkName }}
                            <i class="pwa-link-counter" v-if="totalUnlock > 0">{{totalUnlock}}</i>
                        </a>
                    </li>

                    <li v-else>
                        <a :href="secondaryLink.linkHref" :target="secondaryLink.target" @click="ActivateLoader">
                            {{ secondaryLink.linkName }}
                        </a>
                    </li>
                </ul>
            </div>
            <div>
                <ul>
                    <li class="pwa-overlay-currentLanguage">
                        <a @click="toggleShowLanguages">
                            {{ currentLang }}
                            <i :class="['si si-chevron-down', isActiveLanguages ? 'active' : '' ]"></i>
                        </a>
                    </li>
                </ul>
            </div>

            <div v-if="isActiveLanguages">
                <ul class="navLanguageList" v-for="language in languagesList" :key="language.name">
                    <li>
                        <a :href="language.link"><small>{{ language.name }}</small></a>
                    </li>
                </ul>
            </div>


            <div v-if="initialLinks.length">
                <ul v-for="initialLink in initialLinks" :key="initialLink.linkName">
                    <li>
                        <a :href="initialLink.linkHref" :target="initialLink.target">
                            {{ initialLink.linkName }}
                        </a>
                    </li>
                </ul>
            </div>

        </div>
    </SaiMobileModal>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const {mapGetters, mapActions} = createNamespacedHelpers('searchbar');
import SaiMobileModal from "@/components/common/SaiMobileModal";

export default {
    data() {
        return {
            isActiveLanguages: false
        }
    },
    computed: {
        ...mapGetters([
            'getPwaNavBurgerMenuLinks',
            'isPwaNavOverlayLeftVisible'
        ]),
        systemMessage() {
            return this.getPwaNavBurgerMenuLinks['loggedInLinks']['sysMessage'] ?? '';
        },
        secondaryLinks() {
            return this.getPwaNavBurgerMenuLinks['loggedInLinks']['leftMenuLinks'] ?? [];
        },
        currentLang() {
            return this.getPwaNavBurgerMenuLinks['currentLanguage'];
        },
        languagesList() {
            return this.getPwaNavBurgerMenuLinks['languages'];
        },
        initialLinks() {
            return this.getPwaNavBurgerMenuLinks['primaryLinks'];
        },
        totalUnlock() {
            return this.getPwaNavBurgerMenuLinks['unlockTotalRequest']
        }
    },
    components: {
        SaiMobileModal
    },
    methods: {
        ...mapActions([
            'togglePwaNavOverlayLeft'
        ]),
        hideModal() {
            this.togglePwaNavOverlayLeft(false);
        },
        toggleShowLanguages() {
            return this.isActiveLanguages = !this.isActiveLanguages;
        },
        ActivateLoader() {
            this.$loading.show();
        }
    }
}
</script>

<style lang="scss">
    @import "PwaOverLay";
</style>