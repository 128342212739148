<template>
    <div class="search-results-header">
        <div class="search-results-actions">
            <template v-if="getSearchResultsField('showToolbar')">
                <SearchResultsBulkActions :actions="getToolbarActions"/>
            </template>
            <div class="filterBar" v-if="getSearchResultsField('showFilterSort')">
                <SaiSelectSingle labelBy="key" trackBy="val"
                                 name="orderby"
                                 class="bulkAction"
                                 :reset-after="true"
                                 @change="updateOrderBy"
                                 :placeholder="sortOptionsPlaceHolder"
                                 :options="sortOptions"/>
            </div>
        </div>

        <div class="search-tools" v-if="!isLiteSubscription">
            <div class="search-results-exp">

                <div v-if="isSavedSearch" class="search-tools-delete">
                    <i class="si si-star si-2x"></i>{{ $t('frm_ss') }}
                </div>
                <a v-if="!isSavedSearch" class="search-tools-save"
                   @click="displaySaveSearchModal=true">
                    <i class="si si-star-o si-2x"></i>{{ $t('frm_save_search') }}
                </a>

                <SaveSearchModal :display="displaySaveSearchModal" @closeModal="displaySaveSearchModal=false"/>

                <div class="exportBtn" @click="displayExport=true">
                    <i class="si si-export-document"></i>
                    <strong>Export</strong>
                </div>
                <SearchResultExport :display="displayExport" :fieldOptions="exportFieldOptions"
                                    @close="displayExport=false"/>
            </div>


        </div>
    </div>
</template>

<script>
import {createNamespacedHelpers} from 'vuex';

const {mapGetters, mapActions} = createNamespacedHelpers('searchresults');
const {mapGetters: searechBarGetters} = createNamespacedHelpers('searchbar');

import SaveSearchModal from '../../SearchResults/SaveSearchModal'
import SaiSelectSingle from '@/components/common/SaiSelectSingle';
import SearchResultsBulkActions from '../../SearchResults/SearchResultsBulkActions';
import SearchResultExport from '../../SearchResults/SearchResultExport';
import i18n from "@/plugins/i18n";

export default {
    name: 'SearchResultsHeaderPWA',
    data() {
        return {
            displaySaveSearchModal: false,
            displayExport: false,
            exportFieldOptions: [
                {key: 'docId', val: 'Doc Id'},
                {key: 'name', val: 'Name'},
                {key: 'title', val: 'Title'},
                {key: 'status', val: 'Status'},
                {key: 'language', val: 'Language'},
                {key: 'publisher', val: 'Publisher'},
                {key: 'country', val: 'Country'},
                {key: 'latestVersion', val: 'Latest Version'},
                {key: 'versionDate', val: 'Version Date'},
                {key: 'summary', val: 'Summary'},
            ]
        };
    },
    components: {
        SearchResultsBulkActions,
        SearchResultExport,
        SaveSearchModal,
        SaiSelectSingle
    },
    methods: {
        ...mapActions([
            'setOrderBy',
            'fetchItems',
            'setPWAStatus',
            'setCurrentPage'
        ]),
        updateOrderBy($event) {
            if (String($event.val) !== String(this.getSearchResultsField('orderBy'))) {
                this.setOrderBy(String($event.val));
                this.setPWAStatus(false);
                this.setCurrentPage(1);
                this.fetchItems('collections');
            }
        }
    },
    computed: {
        ...mapGetters([
            'getSearchResultsField',
            'getToolbarActions',
            'isSavedSearch',
            'getSearchResults'
        ]),
        ...searechBarGetters([
            'getActiveSearchTab',
            'isLiteSubscription'
        ]),
        sortOptions: function () {
            return [
                {key: i18n.t('frm_sortBy') + ': ' + i18n.t('frm_relevance'), val: 0},
                {key: i18n.t('frm_sortBy') + ': ' + i18n.t('frm_alphabetic'), val: 1},
            ];
        },
        sortOptionsPlaceHolder: function () {
            let orderBy = this.getSearchResultsField('orderBy');
            orderBy = orderBy ? orderBy : 0;
            return this.sortOptions[orderBy]['key'];
        }
    }
}
</script>

