<template>
    <span class="search-icon-box">
        <svg width="31" height="22" viewBox="0 0 31 29" fill="none" xmlns="http://www.w3.org/2000/svg">
             <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1755 23.5036C23.2939 21.3283 25.6798 15.4155 23.5045 10.2971C21.3292 5.17865 15.4165 2.79274 10.298 4.96803C5.17959 7.14332 2.79369 13.0561 4.96898 18.1745C7.14427 23.293 13.057 25.6789 18.1755 23.5036Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
             <path d="M21.3569 21.3555L29.1669 29.1666" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </span>
</template>

<script>
export default {

}
</script>

<style scoped>

</style>