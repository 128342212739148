<template>
        <span class="profile-icon-box" >
           <svg width="50" height="22" viewBox="10 0 35 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M42 24L48.9983 16.1921C49.2832 15.936 49.716 15.936 50.001 16.1921L57 24" stroke="white" stroke-width="2" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 19.75C19.6244 19.75 22.5625 16.8119 22.5625 13.1875C22.5625 9.56313 19.6244 6.625 16 6.625C12.3756 6.625 9.4375 9.56313 9.4375 13.1875C9.4375 16.8119 12.3756 19.75 16 19.75Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M24.6409 27.0952C22.3541 24.794 19.2439 23.5 15.9996 23.5C12.7554 23.5 9.64521 24.794 7.3584 27.0952" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M16 30.0625C23.7665 30.0625 30.0625 23.7665 30.0625 16C30.0625 8.2335 23.7665 1.9375 16 1.9375C8.2335 1.9375 1.9375 8.2335 1.9375 16C1.9375 23.7665 8.2335 30.0625 16 30.0625Z" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
           </svg>
        </span>
</template>

<script>
export default {
    name: "ProfileIconActive"
}
</script>

<style scoped>

</style>