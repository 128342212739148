<template>
  <div class="search-filter-container">
    <SearchResultFacetsStandards v-if="isActiveSearchTab('search.standards')"/>
    <SearchResultFacetsCollections v-if="isActiveSearchTab('search.assets')"/>
  </div>
</template>

<script>
import SearchResultFacetsStandards from './Standards/SearchResultFacetsStandards';
import SearchResultFacetsCollections from './Collections/SearchResultFacetsCollections';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters } = createNamespacedHelpers('searchresults');

export default {
  name: 'SearchResultFacetsPWA',
  components: {
    SearchResultFacetsStandards,
    SearchResultFacetsCollections
  },
  computed: {
    ...mapGetters([
      'isActiveSearchTab',
    ])
  }
}
</script>

<style lang="scss">
  @import "../SearchResultFacets/SearchResultFacets";
</style>